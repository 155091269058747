<template>
    <div class="inner">
        <v-chart :option="map" :init-options="initOptions" ref="map" autoresizec class="v-map"/>
        <div class="shop">
            <el-row  type="flex" align="bottom">
                <el-col :span="24"><span class="title">门店分布数量</span><span class="subtitle">截止到2023年3月为止</span></el-col>
            </el-row>
            <el-row type="flex">
                <el-col :span="8">
                    <p class="area">华南地区</p>
                    <p class="num">55<span class="dw">家</span></p>
                </el-col>
                <el-col :span="8">
                    <p class="area">华中地区</p>
                    <p class="num">18<span class="dw">家</span></p>
                </el-col>
                <el-col :span="8">
                    <p class="area">华东地区</p>
                    <p class="num">01<span class="dw">家</span></p>
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col :span="8">
                    <p class="area">华北地区</p>
                    <p>01<span class="dw">家</span></p>
                </el-col>
                <el-col :span="8">
                    <p class="area">华西地区</p>
                    <p>03<span class="dw">家</span></p>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import map from '../data/map'
import "echarts"
// import qs from 'qs'

export default {
    name: 'Map',
    data() {
        // const options = qs.parse(location.search, { ignoreQueryPrefix: true })
        return {
            map,
            initOptions: {
                renderer: 'canvas'
            }    
        }
    },
    methods: {
    },
    mounted() {
        // this.map()
        // this.loopshadow()
    }
}
</script>

<style>
.inner{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 888;
}
.v-map{
    z-index: 999;
}
.shop{
    width: 40%;
    height: 30%;
    position: absolute;
    /* background: #fff; */
    bottom: 0%;
    left: 0%;
    text-align: left;
    z-index: 999;
    margin-left: 10px;
}
.title{
    border-left-width: 5px;
    font-size: 18px;
    border-left-color: #fff;
    padding-left: 8px;
    border-left-style: solid;
}
.subtitle{
    font-size: 10px;
    padding-left: 5px;
}
.area{
    font-size: 10px;
}
.dw{
    font-size: 10px;
    padding-left: 5px;
}
.num{
    margin-bottom: 0px !important;
}
</style>
